import {
  LogLevel,
  PublicClientApplication,
  BrowserAuthError,
  EventType,
} from "@azure/msal-browser";
import router from "./router/index.js";
import { api as feathersClient } from "./feathers.js";
import { useStore, loginStatus } from "./store/app.pinia";

// We'll use this flag with a timeout to prevent it from getting stuck
let authInProgress = false;

// Reset auth flag after a timeout to prevent it from getting stuck
const resetAuthFlag = (timeout = 10000) => {
  setTimeout(() => {
    if (authInProgress) {
      console.log("Force resetting auth flag after timeout");
      authInProgress = false;
    }
  }, timeout);
};

const setLoginStatus = (status = loginStatus.loggedOut, clearError = false) => {
  const appStore = useStore();
  appStore.setLoginStatus(status);
  if (clearError) appStore.setAuthError(null);
};

const setLogin = (accessTokenResponse = null) => {
  const appStore = useStore();
  appStore.setLogin(accessTokenResponse);
};

const setAuth = (auth = null) => {
  const appStore = useStore();
  // Set the store
  appStore.setAuth(auth?.authentication || null);
  // Emit frame messages (if we're a frame)
  if (auth && auth.accessToken) {
    frameLogin(auth, auth.accessToken);
  }
};

const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

const config = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_DIRECTORY_ID}`,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    // navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

// Initialize the instance on module load for non-auth operations
export const msalInstance = new PublicClientApplication(config);

// Check if authentication is in progress
export const isAuthInProgress = () => authInProgress;

// Event callback to track auth status
msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_START ||
    event.eventType === EventType.ACQUIRE_TOKEN_START
  ) {
    console.log("Auth interaction started:", event.eventType);
    authInProgress = true;
  } else if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.LOGIN_FAILURE ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
  ) {
    console.log("Auth interaction ended:", event.eventType);
    authInProgress = false;
  }
});

const selectAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();
  // Return if there aren't any accounts
  if (currentAccounts.length === 0) {
    return null;
  }
  // Return the first account
  return currentAccounts[0];
};

export const frameLogin = (authResult, accessToken) => {
  // If we're not an iframe, return
  if (!window.parent || window === window.parent) return false;
  // Determine the requested app groups
  const appGroups = JSON.parse(router.currentRoute?.query?.appGroups ?? "[]");
  // Set up app store and error function
  const appStore = useStore();
  const setError = (message = "Not a member of this app group") => {
    appStore.setAuthError({ message });
    return false;
  };
  // If no group ids, return
  if (!Array.isArray(appGroups) || !appGroups.length) return setError();
  // Check if this member is in the requested groups (check for array intersection)
  if (!appStore.myGroups.filter((id) => appGroups.includes(id)).length)
    return setError();
  // Emit the login event
  window.parent.postMessage({ type: "ssoLogin", accessToken }, "*");
};

export const login = async () => {
  // Reset auth flag if it's stuck
  if (authInProgress) {
    console.log("Auth flag was stuck, resetting it");
    authInProgress = false;
  }

  setLoginStatus(loginStatus.loading, true);

  // Try to reauthenticate
  const reAuthResult = await feathersClient
    .reAuthenticate(false, "azure")
    .catch(() => {});

  // If the reauthentication was successful, we're done!
  if (reAuthResult) {
    setLoginStatus(loginStatus.loggedIn);
    return reAuthResult;
  }

  // If the reauthentication failed, we need to login
  const token = await getToken();

  // Check if we got a token
  if (!token || !token.length) {
    setLoginStatus(loginStatus.loginError);
    return false;
  }

  try {
    // Attempt to authenticate with feathers
    const authResult = await feathersClient.authenticate({
      strategy: "azure",
      bearerToken: token,
    });

    // Success!
    setLoginStatus(loginStatus.loggedIn);
    // Set auth result
    setAuth(authResult);
    // Resolve with the auth result
    return authResult;
  } catch (e) {
    console.error("Authentication error", e);
    setLoginStatus(loginStatus.loginError);
    return false;
  }
};

export const loginPopup = async () => {
  setLoginStatus(loginStatus.loading);

  // Prevent multiple simultaneous auth attempts
  if (authInProgress) {
    console.log("Auth already in progress, resetting flag and trying again");
    authInProgress = false;
  }

  try {
    // Set the flag
    authInProgress = true;

    // Set a safety timeout to prevent stuck state
    resetAuthFlag();

    // Try login popup
    const response = await msalInstance.acquireTokenPopup(loginRequest);

    // Clear flag after success
    authInProgress = false;

    if (response) {
      setLogin(response);
      return response.accessToken;
    }
    return null;
  } catch (error) {
    console.error("Authentication error:", error);

    if (
      error instanceof BrowserAuthError &&
      error.errorCode === "interaction_in_progress"
    ) {
      console.log(
        "Interaction in progress detected, trying a different approach"
      );

      // Force reset auth flag
      authInProgress = false;

      // Wait a moment
      await new Promise((resolve) => setTimeout(resolve, 500));

      try {
        // Try again with safety timeout
        authInProgress = true;
        resetAuthFlag();

        const response = await msalInstance.acquireTokenPopup(loginRequest);

        authInProgress = false;

        if (response) {
          setLogin(response);
          return response.accessToken;
        }
      } catch (retryError) {
        console.error("Retry also failed:", retryError);
      }
    }

    // For any errors, reset flag and fail gracefully
    authInProgress = false;
    setLogin(null);
    setLoginStatus(loginStatus.loginError);
    return null;
  }
};

export const getToken = async () => {
  setLoginStatus(loginStatus.loading);

  // If auth is stuck in progress, reset it
  if (authInProgress) {
    console.log("Auth flag was stuck in getToken, resetting it");
    authInProgress = false;
  }

  const account = selectAccount();
  if (account === null) {
    console.warn("No account cache found");
    return loginPopup();
  }

  try {
    // Set flag but with safety timeout
    authInProgress = true;
    resetAuthFlag();

    // Try silent token acquisition
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    // Reset flag
    authInProgress = false;

    setLogin(response);
    return response.accessToken;
  } catch (error) {
    console.log("Silent token acquisition failed:", error);

    // Reset the flag
    authInProgress = false;

    // Fall back to popup login
    return loginPopup();
  }
};
